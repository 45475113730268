.navigation {
  color: #10131a;
  padding: 20px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: relative;
  font-family: OpenSans-Regular;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: Min(1300px, 90vw);
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 55px;
  }

  &__items-column {
    flex-direction: column;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    align-items: center;
    justify-content: center;
  }

  &__item {
    font-size: 17px;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 10px;
    background-color: transparent;
    font-family: OpenSans-Regular;
    border: none;
    color: #10131a;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: flex-start;
  }

  &__item:hover {
    color: #1b817a;
  }

  &__item:hover + .navigation__wrapper-children__dropdown {
    display: flex;
  }

  &__item-selected {
    color: #1b817a;
  }

  &__logo-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition-duration: 100ms;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }

  &__logo {
    width: 40px;
    position: relative;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../../assets/logo2.svg");
    filter: invert(15%) sepia(15%) saturate(2092%) hue-rotate(156deg)
      brightness(92%) contrast(87%);
  }

  &__logo2 {
    position: absolute;
    left: 10px;
    top: 11px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../../assets/logo2.svg");
    filter: invert(41%) sepia(22%) saturate(1481%) hue-rotate(127deg)
      brightness(94%) contrast(82%);
  }

  &__logo-title {
    margin: 0;
    padding: 0;
    top: 15px;
    left: 60px;
    width: 110px;
    font-size: 22px;
    position: absolute;
  }

  &__menu-item {
    display: none;
    cursor: pointer;
  }

  &__menu-item:hover {
    color: #1b817a;
  }

  &__wrapper-children {
    position: relative;

    &__dropdown {
      display: none;
      color: white;
      background-color: #10131a;
      position: absolute;
      padding: 25px 25px;
      flex-direction: column;
      width: 120px;

      &__item {
        cursor: pointer;
        font-size: 17px;
        color: white;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__item:hover {
        color: rgb(214, 214, 214);
      }
    }
    &__dropdown:hover {
      display: flex;
    }
  }

  &__mobile-item {
    font-size: 19px;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 10px;
    background-color: transparent;
    font-family: OpenSans-Regular;
    border: none;
    color: #10131a;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition-duration: 300ms;
  }

  &__mobile-item-title {
    margin-bottom: 20px;
  }

  &__mobile-item-title-selected {
    text-decoration: underline;
    margin-top: 20px;
  }

  &__mobile-item-selected {
    //transform: translate(50px, 100px);
    color: #1b817a;
  }

  &__mobile-item:hover {
    color: #1b817a;
  }

  &__item-active-mobile {
    color: #1b817a;
  }

  &__mobile-children {
    display: none;
    transition: opacity 0.3s;
    opacity: 0;
    color: #10131a;
    margin-bottom: 10px;
    font-size: 17px;
  }
  &__mobile-children:hover {
    color: #1b817a;
  }
  &__mobile-children-active {
    display: flex;
    opacity: 1;
  }

  @media only screen and (max-width: 700px) {
    &__items {
      display: none;
    }
    &__items-column {
      display: flex;
    }
    &__menu-item {
      display: block;
    }
  }
}

.navigation-mobile-background {
  position: fixed;
  bottom: 0;
  aspect-ratio: 900/450;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/layered-waves-haikei6.svg");
  z-index: -1;
}

.navigation-mobile-background-top {
  position: fixed;
  top: 0;
  aspect-ratio: 900/450;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/layered-waves-haikei6.svg");
  -moz-transform: scaleX(-1) scaleY(-1);
  -o-transform: scaleX(-1) scaleY(-1);
  -webkit-transform: scaleX(-1) scaleY(-1);
  transform: scaleX(-1) scaleY(-1);
  z-index: -1;
}

.navigation-bottom {
  width: 100%;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: OpenSans-Regular;

  &__background-wave {
    position: absolute;
    aspect-ratio: 900/450;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/layered-waves-haikei6.svg");
  }
}
