.home {
  padding: 0 0;
  background-color: #10131a;

  &__background-wave {
    position: absolute;
    aspect-ratio: 900/450;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/layered-waves-haikei6.svg");
  }
}
