.prediction {
  font-family: OpenSans-Regular;

  &__headers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: Min(15vh, 150px);
    margin-bottom: Min(9vh, 90px);
  }

  &__header1 {
    font-size: 20px;
    width: Min(450px, 45vw);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #10131a;
    font-family: OpenSans-Bold;
  }

  &__header2 {
    font-size: 42px;
    width: Min(500px, 45vw);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #10131a;
    font-family: OpenSans-Bold;
  }

  &__box {
    position: relative;
    width: Min(1000px, 95vw);
    border-radius: 15px;
    background-color: white;

    -webkit-box-shadow: 0px 0px 30px 0px #000000;
    box-shadow: 0px 0px 30px 0px #000000;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ::-webkit-scrollbar {
      height: 14px;
      width: 14px;
    }

    ::-webkit-scrollbar {
      width: 2em;
      height: 1em;
    }

    ::-webkit-scrollbar-track {
      background: rgb(208, 208, 208);
      border-radius: 100vw;
      margin-block: 0.5em;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border: 0.25em solid rgb(208, 208, 208);
      border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    // /* Buttons */
    // ::-webkit-scrollbar-button:single-button {
    //   background-color: #bbbbbb;
    //   display: block;
    //   border-style: solid;
    //   height: 13px;
    //   width: 16px;
    // }
  }

  &__box-countdown {
    height: 400px;
    width: Min(300px, 30vw);
    background-color: #efefefff;
    border-radius: 10px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1b817a;
    font-family: "OpenSans-Bold";
  }

  &__box-chart {
    width: Min(700px, 70vw);
    display: flex;
    overflow: auto;

    margin: 10px 0px;
    position: relative;
  }

  @media only screen and (max-width: 850px) {
    &__box {
      flex-direction: column;
    }
    &__box-chart {
      order: 2;
      width: Min(900px, 85vw);
    }
    &__box-countdown {
      width: 300px;
    }
    &__headers {
      flex-direction: column;
    }

    &__header1 {
      order: 2;
      width: Min(1000px, 90vw);
      margin: 20px 0px;
    }
    &__header2 {
      width: Min(1000px, 90vw);
    }
  }

  @media only screen and (max-width: 700px) {
    &__header1 {
      color: white;
    }
  }

  @media only screen and (max-width: 570px) {
    &__header2 {
      color: white;
    }
  }
}

.sample-box {
  display: flex;
  width: 200px;
  height: 340px;
  background-color: blue;
  margin: 20px;
  box-sizing: border-box;
  flex-shrink: 0;
}
