body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: local("OpenSans-ExtraBold"),
    url(./assets/fonts/OpenSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inconsolata-Regular";
  src: local("Inconsolata-Regular"),
    url(./assets/fonts/Inconsolata-Regular.ttf) format("truetype");
}

.screen {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  overflow-x: hidden;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.underlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
