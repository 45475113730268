.api-content {
  position: relative;
  width: 100%;
  background-color: white;
  font-family: OpenSans-Regular;

  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 40px;
    margin-top: 150px;
    margin-bottom: 120px;
    font-family: OpenSans-Bold;
  }

  &__row {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-family: OpenSans-Bold;
  }

  &__item {
    position: relative;
    width: Min(800px, 45vw);
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__subtitle {
    font-size: 28px;
    font-weight: 700;
    width: Min(500px, 45vw);
  }

  &__code {
    width: Min(800px, 45vw);
    background-color: #b6f7f2;
    border-radius: 10px;
    position: relative;
    padding-top: 60px;
    box-sizing: border-box;
    font-family: Inconsolata-Regular;
  }

  &__code-top {
    position: absolute;
    top: 0;
    width: Min(800px, 45vw);
    height: 55px;
    background-color: #10131a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    box-sizing: border-box;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  &__bottom-text {
    width: Min(85vw, 1200px);
    font-size: 25px;
    margin-top: 100px;
    margin-bottom: 110px;
    text-align: center;
  }

  @media only screen and (max-width: 850px) {
    &__row {
      flex-direction: column;
    }

    &__item {
      width: 90vw;
    }

    &__subtitle {
      font-size: 30px;
      font-weight: 700;
      width: Min(500px, 80vw);
    }

    &__code {
      width: 90vw;
    }
    &__code-top {
      width: 90vw;
    }
  }
}
