.play-box {
  width: Min(1000px, 85vw);
  height: 550px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  box-sizing: border-box;

  -webkit-box-shadow: 0px 0px 25px 0px #000000;
  box-shadow: 0px 0px 25px 0px #000000;
}

.play-box-title {
  background-color: turquoise;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  padding: 4px;
}

.navigation-fixed {
  width: 100%;
  position: fixed;
  z-index: 2;
  margin-bottom: 300px;
  top: 0;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  width: 100%;
  padding: 100px 0px;
}

.blue {
  background: #3c31dd;
}

.red {
  background: #c62368;
}

.pink {
  background: #ff0066;
}

.dark {
  background: #10131a;
}

.white {
  background: white;
}

.curve {
  position: absolute;
  height: 225px;
  width: 100%;
  bottom: 0;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #10131a;
  transform: translate(85%, 60%);
  z-index: 1;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #3c31dd;
  transform: translate(-4%, 40%);
  z-index: 2;
}

.bubble::after {
  content: "";
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  height: 85%;
  width: 100%;
  background-color: #0f0f10;
  z-index: -1;
}

.custom-shape-divider-bottom-1642010925 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1642010925 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1642010925 .shape-fill {
  fill: #10131a;
}

.spacer {
  position: absolute;
  aspect-ratio: 900/600;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.spacer-bottom {
  position: relative;
  aspect-ratio: 900/600;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url("../../assets/layered-waves-haikei3.svg");
}

.layer2 {
  background-image: url("../../assets/layered-waves-haikei.svg");
}

.flip {
  transform: rotate(180deg);
}

.vertical {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;

  &__header-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-color: #10131a;
    z-index: -1;
  }

  &__content {
    margin-top: 1400px;
    z-index: 1;
  }
}
