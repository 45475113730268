.chart-content {
  position: relative;
  display: flex;
  width: 150px;
  height: 350px;
  margin-bottom: 10px;
  box-sizing: border-box;
  flex-shrink: 0;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__chart {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  &__axis {
    font-size: 18px;
    width: 150px;
    text-align: right;
    border-top: 1px solid #10131a;
  }

  &__axis-active {
    font-weight: 700;
  }

  &__actual {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #10131a;
    border-radius: 50%;
    left: 141px;
    z-index: 2;
    cursor: pointer;
  }
  &__actual-active {
    width: 10px;
    height: 10px;
    background-color: white;
    border: 3px solid #10131a;
  }
  &__prediction {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #1b817a;
    border-radius: 50%;
    left: 141px;
    z-index: 3;
    cursor: pointer;
  }
  &__prediction-active {
    width: 10px;
    height: 10px;
    background-color: white;
    border: 3px solid #1b817a;
  }
}

.chart-legend {
  position: absolute;
  right: Min(310px, 31vw);
  top: 10px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 17px;
  font-weight: 500;

  &__column {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    cursor: pointer;
    z-index: 5;
  }

  &__box {
    width: 25px;
    height: 25px;
    background-color: #10131a;
    margin: 3px;
    border-radius: 4px;
  }

  &__box-inactive {
    width: 19px;
    height: 19px;
    background-color: white;
    border: 3px solid #10131a;
  }

  &__box2 {
    width: 25px;
    height: 25px;
    background-color: #1b817a;
    margin: 3px;
    border-radius: 4px;
  }

  &__box-inactive2 {
    width: 19px;
    height: 19px;
    background-color: white;
    border: 3px solid #1b817a;
  }
}

@media only screen and (max-width: 850px) {
  .chart-legend {
    right: 20px;
    top: 420px;
  }
}

.chart-detect-hover {
  position: absolute;
  top: 0;
  right: -25px;
  left: 95px;
  bottom: 0;
  color: #10131a;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
}

.chart-tooltip {
  display: none;

  flex-direction: column;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  z-index: 10;
  font-size: 18px;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.82);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.82);

  &__active {
    display: flex;
  }

  &__column2 {
    color: #1b817a;
  }
}

.chart-toggle {
  position: absolute;
  bottom: 82px;
  left: 12px;
  z-index: 20;
  cursor: pointer;
  color: #10131a;
}
.chart-toggle:hover {
  color: #1b817a;
}

@media only screen and (max-width: 850px) {
  .chart-tooltip {
    top: 430px;
  }
}

.mean-average-error {
  height: 400px;
  width: Min(300px, 30vw);
  border-left: 1px solid rgb(172, 172, 172);
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #10131a;
  font-family: "OpenSans-Bold";
  text-align: center;
}

.mea-select {
  --radius: 2px;
  --baseFg: dimgray;
  --baseBg: white;
  --accentFg: #1b817a;
  --accentBg: #cae7e1;

  font-weight: 400;
  font-size: 12px/1.3;
  -webkit-appearance: none;
  appearance: none;
  color: var(--baseFg);
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  padding: 0.65em 2.5em 0.55em 0.75em;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-image: linear-gradient(var(--baseFg), var(--baseFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom,
    right bottom;
}

.mea-select:hover {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
}

.mea-select:active {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
  color: var(--accentBg);
  border-color: var(--accentFg);
  background-color: var(--accentFg);
}

.mea-select-option {
}

.chart-bar-content {
  position: relative;
  display: flex;
  width: 150px;
  height: 350px;
  margin-bottom: 10px;
  box-sizing: border-box;
  flex-shrink: 0;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__middle-line {
    position: absolute;
    bottom: 0;
    left: 1px;
    right: 1px;
    border-top: 1px dashed rgb(162, 162, 162);
    height: 175px;
  }

  &__bar {
    position: absolute;
    bottom: 27px;
    left: 55px;
    right: 55px;
    height: 175px;
    background-color: #1b817a;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__bar:hover {
    background-color: #469e98;
  }

  &__bar-value {
    position: absolute;
    bottom: 27px;
    left: 0px;
    right: 0px;
    height: 175px;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
  }

  &__title {
    position: absolute;
    top: 20px;
    left: 0px;
    width: Min(700px, 65vw);
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    color: #10131a;
  }
}

@media only screen and (max-width: 850px) {
  .chart-bar-content__title {
    top: 225px;
    width: 100%;
  }

  .mean-average-error {
    height: 175px;
    border: none;
    width: 90%;
    border-bottom: 1px solid rgb(172, 172, 172);
  }
}
