.csv-content {
  margin-top: 350px;
  margin-bottom: 350px;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 40px;
    font-family: OpenSans-Bold;
    margin-bottom: 80px;
  }

  &__csv-container {
    width: Min(500px, 45vw);
    height: Min(700px, 70vw);
    background-color: white;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;

    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.95);
    overflow: auto;
    position: relative;
    cursor: grab;
    color: black;
  }

  &__csv-row {
    height: 40px;
    border-bottom: 1px solid rgb(90, 90, 90);
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__csv-column {
    flex-shrink: 0;
    width: 100px;
    height: 40px;
    border-right: 1px solid rgb(90, 90, 90);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::-webkit-scrollbar {
    height: 14px;
    width: 14px;
  }

  ::-webkit-scrollbar {
    width: 1em;
    height: 1em;
  }

  ::-webkit-scrollbar-track {
    background: rgb(208, 208, 208);
    border-radius: 100vw;
    margin-block: 0.5em;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border: 0.25em solid rgb(208, 208, 208);
    border-radius: 100vw;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
