.copy-code {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  font-size: 19px;
  overflow-wrap: break-word;
  word-break: break-all;

  &__item {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-right: 40px;
  }

  &__item-number {
    margin-right: 5px;
    width: 30px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  &__copy {
    position: absolute;
    top: 65px;
    right: 15px;
    display: flex;
  }

  &__copy:hover {
    color: rgb(90, 90, 90);
    cursor: pointer;
  }
}
